<template>
  <div class="row row-equal">
    <div class="flex w-100">
      <div class="row">
        <div
          class="flex xs12 sm4"
          v-for="(info, idx) in infoTiles"
          :key="idx"
        >
          <va-card class="mb-4" :color="info.color">
            <p class="display-2 mb-0 text-center" style="color: white;">{{ info.value }}</p>
            <p class="text-center">{{$t('dashboard.info.' + info.text)}}</p>
          </va-card>
        </div>
      </div>

      <div class="row">
        <div class="flex xs12 md6">
          <va-card>
            <p class="display-2 mb-1 text--center" :style="{color: this.$themes.primary}">291 {{$t('dashboard.info.minutes')}}</p>
            <p class="no-wrap text--center">{{$t('dashboard.info.completedPullRequests')}}</p>
          </va-card>
        </div>
        <div class="flex xs12 md6">
          <va-card>
            <p class="display-2 mb-1 text--center"  :style="{color: this.$themes.primary}">
              3.1 / {{$t('dashboard.info.minutes')}}
            </p>
            <p class="no-wrap text--center">{{$t('dashboard.info.completedPullRequests2')}}</p>
          </va-card>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'DashboardInfoBlock',
  data () {
    return {
      infoTiles: [{
        color: 'info',
        value: '803',
        text: 'commits',
        icon: '',
      }, {
        color: 'success',
        value: '750',
        text: 'components',
        icon: '',
      }, {
        color: 'danger',
        value: '53',
        text: 'teamMembers',
        icon: '',
      }],
      modal: false,
      currImage: 0,
      images: [
        'https://i.imgur.com/qSykGko.jpg',
        'https://i.imgur.com/jYwT08D.png',
        'https://i.imgur.com/9930myH.jpg',
        'https://i.imgur.com/2JxhWD6.jpg',
        'https://i.imgur.com/MpiOWbM.jpg',
      ],
    }
  },
  methods: {
    showModal () {
      this.modal = true
    },
    showPrevImage () {
      this.currImage = !this.currImage ? this.images.length - 1 : this.currImage - 1
    },
    showNextImage () {
      this.currImage = this.currImage === this.images.length - 1 ? 0 : this.currImage + 1
    },
  },
}
</script>

<style lang="scss">
  .row-separated {
    .flex + .flex {
      border-left: 1px solid #e3eaeb;
    }

    @include media-breakpoint-down(xs) {
      p:not(.display-2) {
        font-size: 0.875rem;
      }
    }
  }

  .dashboard {
    .va-card__header--over {
      @include media-breakpoint-up(md) {
        padding-top: 0 !important;
      }
    }

    .va-card__image {
      @include media-breakpoint-up(md) {
        padding-bottom: 0 !important;
      }
    }
  }
</style>
