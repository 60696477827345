<template>
  <div class="row row-equal">
    <div class="flex xs12 xl6">
      <va-card :title="$t('dashboard.charts.trendyTrends')">
        <va-chart class="chart" ref="lineChart" :data="lineChartData" type="line"/>
      </va-card>
    </div>

    <div class="flex xs12 md6 xl3">
      <va-card :title="$t('dashboard.charts.loadingSpeed')">
        <va-chart class="chart chart--donut" :data="donutChartData" type="donut"/>
      </va-card>
    </div>

    <div class="flex xs12 md6 xl3">
      <dashboard-contributors-chart/>
    </div>
  </div>
</template>

<script>
import { getDonutChartData } from '../../data/charts/DonutChartData'
import { getLineChartData } from '../../data/charts/LineChartData'
import DashboardContributorsChart from './DashboardContributorsList'

export default {
  name: 'dashboard-charts',
  components: { DashboardContributorsChart },
  data () {
    return {
      lineChartData: getLineChartData(this.$themes),
      donutChartData: getDonutChartData(this.$themes),
      lineChartFirstMonthIndex: 0,
    }
  },
  watch: {
    '$themes.primary' () {
      this.lineChartData = getLineChartData(this.$themes)
      this.donutChartData = getDonutChartData(this.$themes)
    },

    '$themes.info' () {
      this.lineChartData = getLineChartData(this.$themes)
      this.donutChartData = getDonutChartData(this.$themes)
    },

    '$themes.danger' () {
      this.donutChartData = getDonutChartData(this.$themes)
    },
  },
  methods: {
    deleteSection () {
      this.lineChartFirstMonthIndex += 1
      this.lineChartData = getLineChartData(this.$themes, this.lineChartFirstMonthIndex)
      this.$refs.lineChart.$refs.chart.refresh()
    },
    printChart () {
      var arrrayBuffer = base64ToArrayBuffer('JVBERi0xLjYKJdP0zOEKMSAwIG9iago8PAovQ3JlYXRpb25EYXRl') // data is the base64 encoded string
      function base64ToArrayBuffer (base64) {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      }
      var pdfFile = new Blob([arrrayBuffer], {
        type: 'application/pdf',
      })
      var pdfUrl = URL.createObjectURL(pdfFile)
      const win = window.open(pdfUrl, 'Print', 'height=600,width=800')
      // const win = window.open('http://mahmmoudahmed-001-site4.ftempurl.com/images/AhmedMaghraby.pdf', 'Print', 'height=600,width=800')
      // win.document.write(`<br><img src='${this.donutChartDataURL}'/>`)
      // TODO: find better solution how to remove timeout
      setTimeout(() => {
        win.document.close()
        win.focus()
        win.print()
        win.close()
      }, 1000)
    },
  },
  computed: {
    donutChartDataURL () {
      return document.querySelector('.chart--donut canvas').toDataURL('image/png')
    },
  },
}
</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
