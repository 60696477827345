export const getVerticalBarChartData = (themes) => ({
  labels: ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'],
  datasets: [
    {
      label: 'الحجاج',
      backgroundColor: themes.primary,
      borderColor: 'transparent',
      data: [50, 20, 12, 39, 10, 40, 39],
    },
    {
      label: 'المتوقع',
      backgroundColor: themes.info,
      borderColor: 'transparent',
      data: [50, 10, 22, 39, 15, 20, 85],
    },
  ],
})
