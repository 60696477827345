<template>
  <div class="dashboard">
    <div class="d-flex va-card">

      <img
        src="https://png.pngtree.com/png-clipart/20230207/ourmid/pngtree-islamic-clipart-holy-kaaba-in-mecca-saudi-arabia-watercolor-png-image_6590456.png"
        style="max-width: 20%;" class="flex md2 xs2" />
      <div class="flex md10 xs10">

        <div class="row h-100">
          <p class="flex xs12 display-2 mt-3 text--start">
            حالة اليوم
          </p>
          <div class="d-flex flex align--center justify--space-between flex md4">

            <va-progress-circle style="max-width: 50%;" :value="40" :color="'success'">
              <div class="text--center ">
                40%
                <br />
                نسبة إزدحام المواقف
              </div>
            </va-progress-circle>

          </div>
          <div class="d-flex align--center justify--space-between flex md4">
            <va-progress-circle style="max-width: 50%;" :value="80" :color="'danger'">
              <div class="text--center ">
                80%
                <br />
                نسبة إزدحام الحرم
              </div>
            </va-progress-circle>
          </div>
          <div class="d-flex align--center justify--space-between flex md4">
            <va-progress-circle style="max-width: 50%;" :value="60" :color="'info'">
              <div class="text--center ">
                60%
                <br />
                نسبة إزدحام الساحات
              </div>
            </va-progress-circle>
          </div>
        </div>
      </div>
    </div>
    <dashboard-charts />
    <div class="row">
      <div class="flex md12 xs12">
        <va-card class="chart-widget" :title="$t('charts.bubbleChart')">
          <va-chart :data="bubbleChartData" type="bubble" />
        </va-card>
      </div>
    </div>
    <div class="row row-equal">
      <div class="flex md6 xs12">
        <va-card class="chart-widget" :title="$t('charts.verticalBarChart')">
          <va-chart :data="verticalBarChartData" type="vertical-bar" />
        </va-card>
      </div>
      <div class="flex md6 xs12">
        <dashboard-info-block></dashboard-info-block>

      </div>
    </div>
  </div>
</template>

<script>
import DashboardCharts from './DashboardCharts'
import DashboardInfoBlock from './DashboardInfoBlock'
import { getBubbleChartData } from '../../data/charts/BubbleChartData'
import { getVerticalBarChartData } from '../../data/charts/VerticalBarChartData'
import { getHorizontalBarChartData } from '../../data/charts/HorizontalBarChartData'
import VaChart from '../statistics/charts/va-charts/VaChart'
// import DashboardMap from './DashboardMap'

export default {
  name: 'dashboard',
  components: {
    DashboardCharts,
    DashboardInfoBlock,
    VaChart,
    // DashboardMap,
  },
  data () {
    return {
      bubbleChartData: getBubbleChartData(this.$themes),
      verticalBarChartData: getVerticalBarChartData(this.$themes),
      horizontalBarChartData: getHorizontalBarChartData(this.$themes),
    }
  },
  created () {
    document.title = this.$t('generic.RCRCBus') + '-' + this.$t('menu.dashboard')
  },
  methods: {
    addAddressToMap ({ city, country }) {
      this.$refs.dashboardMap.addAddress({ city: city.text, country })
    },
  },
}
</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;
  }

  .va-progress-circle__info {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, 50%);
    font-size: 0.625rem;
    left: auto;
  }
}
</style>
