<template>
  <va-card
    :title="$t('dashboard.charts.topContributors')"
    class="d-flex dashboard-contributors-list"
  >
    <va-inner-loading :loading="loading">
      <div
        class="mb-3"
        v-for="(contributor, idx) in visibleList"
        :key="idx"
      >
        <va-progress-bar
          :value="getPercent(contributor.val)"
          :color="getRandomColor()"
        >
          {{ contributor.val }} {{ $t('dashboard.charts.commits') }}
        </va-progress-bar>
        <p class="mt-2">{{ contributor.name }}</p>
      </div>
    </va-inner-loading>
  </va-card>
</template>

<script>
export default {
  name: 'DashboardContributorsList',
  data () {
    return {
      contributors: [],
      loading: false,
      progressMax: 350,
      visibleList: [{
        name: 'الصفا و المروة',
        val: 340,
      }, {
        name: 'الساحات الجنوبية',
        val: 280,
      }, {
        name: 'صحن الطواف',
        val: 240,
      }, {
        name: 'مواقف شعب عامر',
        val: 190,
      }, {
        name: 'مواقف محبس الجن',
        val: 180,
      }],
    }
  },
  methods: {
    getPercent (val) {
      return (val / this.progressMax) * 100
    },
    showNext () {
      this.visibleList = this.contributors.splice(0, this.step)
    },
    getRandomColor () {
      const keys = Object.keys(this.$themes)
      return this.$themes[keys[keys.length * Math.random() << 0]]
    },
  },
}
</script>

<style scoped lang="scss">
.dashboard-contributors-list {
  flex-direction: column;

  .inner-loading {
    height: 100%;
  }
}
</style>
